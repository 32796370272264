/**
 * 安全字符串
 * 处理一些如：U+202E 之类的特殊字符
 */
export const saveString = (str: string) => str.replace('‮', '');



export const getUrlId = (url) => {
  let urlId = '0';
  try {
    const { pathname } = new URL(url);
    const matchRes = pathname.match(/\/(\d+)(\/[\w_-]+)\/([A-Za-z0-9]+)/i);
    urlId = matchRes?.[3] || '0';
  } catch (error) {
  }

  return urlId;
};



export const saveJsonParse = (text: string, defaultValue: any = ''): any => {
  let v = defaultValue;
  try {
    v = JSON.parse(text);
  } catch (error) {
    // console.log(text);

    console.log('parse 失败');
  }
  return v;
};
